@import "./colors";
@import "./media-queries";

.main {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: $white;
    font-family: "GT-America-Bold", helvetica, sans-serif;
    opacity: 1;
    transition: opacity 0.25s ease-out;

    &--hidden {
        opacity: 0;
    }

    &__center {
        width: 100%;
        text-align: center;
        transform: translateY(-48px);

        @include desktop1440 {
            transform: translateY(-32px);
        }

        &__bottom,
        &__top {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $gray;
            font-size: 20px;

            @include phoneAndTablet {
                font-size: 16px;
            }

            @include desktop1440 {
                font-size: 16px;
            }

            a {
                text-decoration: none;
                color: $gray;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                    color: $yellow;
                }
            }
        }

        &__middle {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 128px 0;
            color: $yellow;
            font-size: 64px;

            @include phoneAndTablet {
                width: 100%;
                font-size: 40px;
            }

            @include desktop1440 {
                font-size: 48px;
            }

            &__logo {
                width: auto;
                height: 64px;
            }
        }
    }

    &__corner {
        position: absolute;

        &--bottom {
            &-right {
                top: 100%;
                left: 100%;
                font-family: "GT-America-Mono", helvetica, sans-serif;
                transform: rotate(-90deg) translate(16px, 60px);
                z-index: 3;

                @include phoneAndTablet {
                    display: none;
                }

                @include desktop1440 {
                    font-size: 14px;
                    transform: rotate(-90deg) translate(16px, 36px);
                }
            }
        }

        &--top {
            &-left {
                top: 0;
                left: 0;

                img {
                    width: 200px;
                    height: auto;
                    transform: translate(-50%, -50%);

                    @include phoneAndTablet {
                        width: 100px;
                        transform: translate(2px, -1px);
                    }
                }
            }
        }
    }
}
