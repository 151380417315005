.playlist {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    iframe {
        box-shadow: 8px 8px 8px 2px #ccc;
    }
}
