@import "./colors";
@import "./media-queries";

.email-modal {
    &__form {
        display: grid;
        grid-column-gap: 8px;
        grid-template-columns: repeat(2, calc(50% - 4px));
        max-width: 100%;

        &__button {
            width: 100%;
            border: 1px solid $black;
            padding: 8px;
            background-color: $black;
            color: $white;
            font-family: "GT-America-Bold", helvetica, sans-serif;
            font-size: 16px;
        }

        &__input {
            width: 100%;
            margin-bottom: 8px;
            padding: 8px;
            border: 1px solid $gray;
            text-align: center;

            @include phoneAndTablet {
                margin-bottom: 4px;
            }

            &::placeholder {
                color: $gray;
            }
        }
    }

    &__body {
        margin-bottom: 24px;
        color: $gray;
        font-family: "GT-America-Bold", helvetica, sans-serif;
        font-size: 17.5px;
        text-align: center;

        @include phoneAndTablet {
            margin-bottom: 16px;
            font-size: 14px;
        }
    }

    &__header {
        color: $yellow;
        font-family: "GT-America-Compressed-Bold", helvetica, sans-serif;
        font-size: 46px;
        text-align: center;

        @include phoneAndTablet {
            font-size: 36px;
        }
    }
}

/**************************
* Bootstrap Overrides
***************************/

.modal {
    &-body {
        padding-top: 0 !important;
    }

    &-header {
        border-bottom: none !important;
    }
}

/**************************
* Mailchimp Overrides
***************************/

#mc_embed_signup {
    width: 100%;
}

.mc-field-group-email {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
}

.mc-field-group-fname {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
}

.mc-field-group-lname {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
}

#mc-embedded-subscribe {
    grid-column: 1 / span 2;
    grid-row: 3 / span 1;
}
