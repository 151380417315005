@import "./colors";
@import "./media-queries";

.menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 48px 128px;
    background-color: $off-white;
    background-image: url("../media/menu-background.jpg");
    background-size: contain;
    z-index: 100;
    overflow: hidden;

    @include phoneAndTablet {
        justify-content: flex-start;
        height: calc(100% - 80px);
        padding: 32px;
    }

    &__atg-studios-tag {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translate(16px, -150%);

        span {
            text-decoration: none;
            color: $gray-dark;
            font-family: "GT-America-Bold", helvetica, sans-serif;
            font-size: 12px;
            transition: color 0.25s ease-out;

            // &:hover {
            //     text-decoration: none;
            //     color: $black;
            // }
        }
    }

    &__item {
        position: relative;
        width: 150px;
        height: auto;
        cursor: pointer;

        @include phone {
            width: 112px;
            margin-bottom: 32px;
        }

        @include tablet {
            margin-bottom: 64px;
        }

        @include desktop1440 {
            width: 125px;
        }

        &--first,
        &--third {
            transform: translateX(-16px);
        }

        &--second,
        &--fourth {
            transform: translateX(16px);
        }

        &--first {
            .menu__item__text {
                span {
                    transform: rotate(-15deg);
                }
            }
        }

        &--second {
            .menu__item__text {
                span {
                    transform: rotate(-10deg);
                }
            }
        }

        &--third {
            .menu__item__text {
                span {
                    transform: rotate(5deg);
                }
            }
        }

        &--fourth {
            .menu__item__text {
                span {
                    transform: rotate(15deg);
                }
            }
        }

        &__circle {
            width: 100%;
            height: auto;
        }

        &__smiley {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 75%;
            height: auto;
            transform: translate(-50%, -50%);

            &:hover {
                animation: rotation 2s infinite linear;
            }
        }

        &__text {
            position: absolute;
            top: 50%;
            left: 50%;
            font-family: "Permanent Marker", helvetica, sans-serif;
            font-size: 24px;
            transform: translate(-50%, -50%);

            span {
                display: inline-block;
            }

            @include phone {
                font-size: 20px;
            }
        }
    }
}

.menu-mobile {
    display: none;

    @include phoneAndTablet {
        position: absolute;
        top: 56px;
        left: 0;
        display: flex;
        width: 100vw;
        height: calc(100vh - 56px);
        opacity: 0;
        transition: opacity 0.25s ease-out;
        z-index: 0;

        &--open {
            opacity: 1;
            z-index: 100;
            overflow: hidden;
        }
    }
}

@keyframes rotation {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(359deg);
    }
}
