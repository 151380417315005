@import "./media-queries";

.video {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.25s ease-out;

    @include phoneAndTablet {
        height: calc(100% - 80px);
    }

    &--hidden {
        opacity: 0;
    }

    & > * {
        transform: translateY(-56px);

        @include phoneAndTablet {
            transform: translateY(-120px);
        }
    }
}
