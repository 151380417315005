@import "./colors";
@import "./media-queries";

.social-buttons {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 100%;
    left: 50%;
    width: 50vw;
    padding: 24px;
    background-color: $white;
    transform: translate(0, -100%);

    @include phoneAndTablet {
        width: 100vw;
        padding: 16px;
        transform: translate(-50%, calc(-100%));
    }

    & > * {
        width: 16px;
        height: auto;
        cursor: pointer;
        opacity: 0.5;
        transition: opacity 0.25s ease-out;

        &:hover {
            opacity: 1;
        }

        &:not(:last-child) {
            margin-right: 24px;
        }
    }
}
