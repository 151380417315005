@import "./colors";
@import "./media-queries";

.burger {
    display: none;

    @include phoneAndTablet {
        position: absolute;
        top: 16px;
        left: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: transparent;
        transform: translate(calc(-100% - 20px), 0);
        z-index: 99;

        &:focus {
            outline: none;
        }

        .burger__line {
            position: relative;
            width: 100%;
            height: 2px;
            border-radius: 8px;
            background: black;
            opacity: 1;
            transform-origin: 1px;
            transition: all 0.25s ease-out;

            &:not(:last-child) {
                margin-bottom: 6px;
            }

            &:first-child {
                &.burger__line--open {
                    transform: rotate(47deg);
                }
            }

            &:nth-child(2) {
                &.burger__line--open {
                    opacity: 0;
                    transform: translateX(20px);
                }
            }

            &:nth-child(3) {
                &.burger__line--open {
                    transform: rotate(-47deg);
                }
            }
        }
    }
}
