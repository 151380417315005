$black: #000000;
$gray: #b8b8b8;
$gray-dark: #a9a9a9;
$gray-light: #f0f0f0;
$green: #78c04c;
$off-white: #e7ebe1;
$pink: #fca9b2;
$purple: #bc5ba2;
$white: #ffffff;
$yellow: #fdda00;
