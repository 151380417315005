@import "./media-queries";

.music {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 1;
    transform: translateY(-32px);
    transition: opacity 0.25s ease-out;

    @include phoneAndTablet {
        height: calc(100% - 80px);

        & > * {
            transform: translateY(-96px);
        }
    }

    &--hidden {
        opacity: 0;
    }

    &__icons {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 32px 0 32px 0;

        @include desktopMid {
            margin: 16px 0 16px 0;
        }

        @include phone {
            margin: 64px 0 32px 0;
        }

        &__icon {
            width: 48px;
            height: auto;
            cursor: pointer;
            opacity: 0.5;
            transition: opacity 0.25s ease-out;
            z-index: 1;

            &:hover {
                opacity: 1;
            }

            &:not(:last-child) {
                margin-right: 16px;
            }

            @include desktopMid {
                width: 32px;
            }

            @include phone {
                width: 32px;
            }

            &--selected {
                opacity: 1;
            }
        }
    }
}
