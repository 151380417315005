$tablet-width: 768px;
$desktop-width-min: 1028px;
$desktop-width-1440: 1440px;
$desktop-width-1680: 1680px;
$desktop-width-1920: 1920px;

@mixin phone {
    @media only screen and (max-width: #{$tablet-width - 1px}) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: #{$tablet-width}) and (max-width: #{$desktop-width-min - 1px}) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}

@mixin phoneAndTablet {
    @media only screen and (max-width: #{$desktop-width-min - 1px}) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}

@mixin desktopMid {
    @media only screen and (min-width: #{$desktop-width-min}) and (max-width: #{$desktop-width-1920 - 1px}) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}

@mixin desktop1440 {
    @media only screen and (min-width: #{$desktop-width-1440}) and (max-width: #{$desktop-width-1680 - 1px}) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}

@mixin desktop1680 {
    @media only screen and (min-width: #{$desktop-width-1680}) and (max-width: #{$desktop-width-1920 - 1px}) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}
