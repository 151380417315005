@import "./colors";
@import "./media-queries";

.slideshow {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & * {
        opacity: 1;
        transition: opacity 0.25s ease-out;

        &.hidden {
            opacity: 0;
        }
    }

    &__arrows {
        display: flex;
        margin-bottom: 32px;

        @include desktop1440 {
            margin-bottom: 24px;
        }

        &__arrow {
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            transition: all 0.25s ease-out;
            cursor: pointer;

            @include desktop1440 {
                border-top: 14px solid transparent;
                border-bottom: 14px solid transparent;
            }

            &__left {
                margin-right: 24px;
                border-right: 40px solid $yellow;

                @include desktop1440 {
                    border-right: 30px solid $yellow !important;
                }

                &:hover {
                    border-right: 40px solid $pink;

                    @include desktop1440 {
                        border-right: 30px solid $pink !important;
                    }
                }

                &--disable,
                &--disable:hover {
                    border-right: 40px solid $gray-light;

                    @include desktop1440 {
                        border-right: 30px solid $gray-light !important;
                    }
                }
            }

            &__right {
                border-left: 40px solid $yellow;

                @include desktop1440 {
                    border-left: 30px solid $yellow !important;
                }

                &:hover {
                    border-left: 40px solid $pink;

                    @include desktop1440 {
                        border-left: 30px solid $yellow !important;
                    }
                }

                &--disable,
                &--disable:hover {
                    border-left: 40px solid $gray-light;

                    @include desktop1440 {
                        border-left: 30px solid $gray-light !important;
                    }
                }
            }
        }
    }

    &__button {
        display: flex;
        width: 75%;
        height: 168px;
        cursor: pointer;

        @include phoneAndTablet {
            height: 120px;
        }

        @include desktopMid {
            width: 70%;
        }

        @include desktop1440 {
            height: 96px;
        }

        &__left {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 168px;
            height: 100%;
            background-color: $pink;
            background-image: url("../media/texture-pink.jpg");

            @include phoneAndTablet {
                width: 120px;
            }

            @include desktop1440 {
                width: 96px;
            }
        }

        &__right {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% - 168px);
            height: 100%;
            background-color: $yellow;
            font-family: "GT-America-Bold-Italic", helvetica, sans-serif;
            font-size: 40px;
            text-align: center;

            @include phoneAndTablet {
                width: calc(100% - 120px);
                font-size: 32px;
                text-align: center;
            }

            @include desktopMid {
                font-size: 36px;
            }

            @include desktop1440 {
                width: calc(100% - 96px);
            }

            @include desktop1680 {
                font-size: 32px;
            }
        }

        &__play {
            position: relative;
            width: 60%;
            height: 60%;
            border: 8px solid $yellow;
            border-radius: 50%;

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 0;
                height: 0;
                border-top: 24px solid transparent;
                border-bottom: 24px solid transparent;
                border-left: 36px solid $yellow;
                transform: translate(calc(-50% + 4px), -50%);
                content: "";

                @include phoneAndTablet {
                    border-top: 16px solid transparent;
                    border-bottom: 16px solid transparent;
                    border-left: 24px solid $yellow;
                }

                @include desktopMid {
                    border-top: 12px solid transparent;
                    border-bottom: 12px solid transparent;
                    border-left: 20px solid $yellow;
                }

                @include desktop1680 {
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;
                    border-left: 32px solid $yellow;
                }
            }
        }
    }

    &__image {
        position: relative;
        width: 75%;
        margin-bottom: 24px;
        padding-top: 75%;
        background-size: contain;
        box-shadow: 8px 8px 8px 2px #ccc;
        cursor: pointer;
    }

    &__spotify {
        width: 75%;
        height: 80px;
    }

    &__youtube {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        box-shadow: 8px 8px 8px 2px #ccc;

        &__embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
