@import "./media-queries.scss";

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding: 112px 128px 64px 128px;
    opacity: 1;
    overflow: scroll;
    z-index: 2;
    transition: opacity 0.25s ease-out;

    @include phoneAndTablet {
        padding: 16px;
        overflow: visible;
    }

    @include desktop1440 {
        padding: 112px 96px 64px 96px;
    }

    &--expanded {
        padding: 112px 96px 64px 96px;
    }

    &--hidden {
        opacity: 0;
    }
}
