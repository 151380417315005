@import "./colors";
@import "./media-queries";

.header-bar {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 24px 16px 16px;
    background-color: white;
    font-family: "GT-America-Bold", helvetica, sans-serif;
    z-index: 3;

    @include phoneAndTablet {
        padding: 16px;
    }

    &__sticker {
        height: 64px;
        color: $black;
        font-family: "GT-America-Bold", helvetica, sans-serif;
        cursor: pointer;

        @include phoneAndTablet {
            height: 16px;
        }

        @include desktop1440 {
            height: 48px;
        }
    }

    &__text {
        @include desktop1440 {
            font-size: 12px;
        }
    }

    & + *:not(.main) {
        @include phoneAndTablet {
            transform: translateY(80px);
        }
    }
}
