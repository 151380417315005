@import "./colors";
@import "./media-queries";

@font-face {
    font-family: "GT-America-Bold";
    src: url("../fonts/GT-America-Bold.otf") format("opentype");
}

@font-face {
    font-family: "GT-America-Bold-Italic";
    src: url("../fonts/GT-America-Bold-Italic.otf") format("opentype");
}

@font-face {
    font-family: "GT-America-Compressed-Bold";
    src: url("../fonts/GT-America-Compressed-Bold.otf") format("opentype");
}

@font-face {
    font-family: "GT-America-Mono";
    src: url("../fonts/GT-America-Mono-Regular.otf") format("opentype");
}

.app {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 50% 50%;

    @include phoneAndTablet {
        max-height: 100vh;
        display: flex;
    }
}

h1 {
    color: $yellow;
    font-family: "GT-America-Compressed-Bold", helvetica, sans-serif;
}
