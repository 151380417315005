@import "./colors";
@import "./media-queries";

.tour {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.25s ease-out;

    @include phoneAndTablet {
        top: 0;
        display: block;
    }

    @include desktop1440 {
        transform: translateY(-16px);
    }

    &--hidden {
        opacity: 0;
    }

    &--no-dates {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateY(-32px);

        @include phoneAndTablet {
            & > * {
                transform: translateY(-64px);
            }
        }
    }

    &__dates {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-family: "GT-America-Bold", helvetica, sans-serif;
        font-size: 18px;
        text-transform: uppercase;
        overflow-y: scroll;

        @include desktop1440 {
            font-size: 16px;
        }

        @include phone {
            font-size: 16px;
        }

        @include phoneAndTablet {
            margin-bottom: 96px;
        }

        &__date {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 32px 0;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }

            &:not(:last-child) {
                border-bottom: 2px solid $black;
            }

            &__left {
                display: flex;
                align-items: center;
                flex-grow: 1;

                & > *:not(:last-child) {
                    @include phoneAndTablet {
                        margin-right: 32px;
                    }
                }

                &__day {
                    width: 72px;
                    margin-right: 48px;
                    color: $gray;

                    @include desktopMid {
                        width: 64px;
                        margin-right: 32px;
                    }

                    @include phone {
                        width: 60px;
                        margin-right: 16px !important;
                    }
                }

                &__location {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-grow: 1;

                    @include phoneAndTablet {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    &__geo {
                        display: flex;
                        flex-direction: column;

                        @include phone {
                            flex-direction: row;
                        }
                    }

                    &__venue {
                        text-align: right;

                        @include phoneAndTablet {
                            text-align: left;
                        }
                    }
                }
            }

            &__link {
                text-decoration: none;
                display: inline-block;
                margin-left: 48px;
                padding: 6px 8px;
                background-color: $yellow;
                color: $black;
                transition: all 0.25s ease-out;

                @include desktopMid {
                    margin-left: 32px;
                }

                @include phone {
                    margin-left: 16px !important;
                }

                &:hover {
                    text-decoration: none;
                    background-color: $pink;
                    color: $black;
                }
            }

            &__right {
                display: flex;
                align-items: center;
            }
        }

        &--no-dates {
            width: 100%;
            color: $gray;
            font-size: 20px;
            text-align: center;

            @include phoneAndTablet {
                font-size: 16px;
            }

            @include desktop1440 {
                font-size: 16px;
            }
        }
    }

    &__header {
        width: 100%;
        margin-bottom: 32px;
        font-size: 64px;
        text-align: center;

        @include phoneAndTablet {
            font-size: 36px;
        }

        @include desktopMid {
            font-size: 60px;
        }

        @include desktop1440 {
            font-size: 56px;
        }
    }
}

#seated-55fdf2c0 {
    overflow-y: auto;
}
